<!-- 车辆发票上传 -->
 <template>
  <div class="uploadVehicleInvoice">
    <van-uploader
      class="upload"
      accept="image/*"
      :file-list="fileList"
      :preview-options="{closeable: true}"
      :before-read="beforeRead"
      deletable
      :after-read="(file) => afterRead(file)"
      :before-delete="(file) => beforeDelete(file)"
      :max-count="1">
    </van-uploader>
    <div style="color: rgba(100, 101, 102, 0.6);font-size: 12px;">
      {{ $t('请拍摄或上传发票照片，系统会自动识别发票相关信息')}}
    </div>
    <!-- 发票识别后弹窗提醒 -->
    <van-dialog
      v-model="invoiceModal"
      :confirm-button-text="$t('重新拍摄')"
      confirmButtonColor="#B9921A"
      cancelButtonColor="#029640"
      class="invoiceModalBox"
      :title="$t('提醒')"
      @confirm="reShoot">
      <div class="content" @click="goDetail" v-html="ocrTxt"></div>
    </van-dialog>
  </div>
 </template>
<script>
import { v4 as uuidv4 } from 'uuid'
import { uploadFile } from '@/utils/cos'
import deliveryServices from '@/services/deliveryServices'
import loading from '@/utils/loading'
import dayjs from 'dayjs'
export default {
  data() {
    return {
      fileList: [],
      invoiceModal: false,
      ocrTxt: '',
      deliveryOrder: {},
      ocrInvoice: {},
      jumpTo: false,
    }
  },
  methods: {
    // 上传前校验
    beforeRead(file) {
      if (!file.type.includes('image')) {
        this.$toast.fail(this.$t('请上传正确图片文件'))
        return false
      }
      return true
    },
    afterRead(file) {
      loading.showLoading()
      const uuid = uuidv4()
      const content = file.content
      file.status = 'uploading'
      file.message = this.$t('上传中...')
      file.uuid = uuid
      uploadFile(file.file).then(({ url }) => {
        loading.hideLoading()
        file.status = 'done'
        file.message = ''
        file.url = content
        file.cosUrl = url
        this.fileList.push(file)
        this.cosRecognition(url)
      }).catch(() => {
        loading.hideLoading()
        file.status = 'failed'
        file.message = this.$t('上传失败')
        this.fileList.push(file)
      })
    },
    beforeDelete(file){
      this.fileList = this.fileList.filter(item => item.uuid !== file.uuid)
    },
    // ocr识别
    async cosRecognition(imgUrl) {
      loading.showLoading()
      const params = {
        imgUrl,
      }
      this.ocrTxt = ''
      deliveryServices.ocrDeliveryOrder(params).then(res => {
        loading.hideLoading()
        const { code, msg, data } = res
        if (code !== 0) {
          this.invoiceModal = true
          this.ocrTxt = msg
        } else {
          const { ocrDeliveryOrderError, ocrInvoice, deliveryOrder, deliveryTaskRes, invoiceStoreTime } = data
          this.deliveryOrder = deliveryOrder
          this.ocrInvoice = ocrInvoice
          if (ocrDeliveryOrderError === 1) {
            this.jumpTo = true
            this.invoiceModal = true
            this.ocrTxt = `${this.$t('发票识别的VIN')}(${ocrInvoice.vin})${this.$t('对应的交付单')}(<span style="color: #0040c5; cursor: pointer">${deliveryOrder.orderType === 3 ? deliveryOrder.appOrderId : deliveryOrder.dcosOrderId}</span>)${this.$t('还没收到开票成功，请确认。')}`
          } else if (ocrDeliveryOrderError === 2) {
            this.jumpTo = true
            this.invoiceModal = true
            this.ocrTxt = `${this.$t('发票识别的VIN')}(${ocrInvoice.vin})${this.$t('对应的交付单')}(<span style="color: #0040c5; cursor: pointer">${deliveryOrder.orderType === 3 ? deliveryOrder.appOrderId : deliveryOrder.dcosOrderId}</span>)${this.$t('已上传发票，请确认。')}`
          } else if (ocrDeliveryOrderError === 0) {
            const { id: taskId, taskStatus } = deliveryTaskRes
            const { id, orderType } = deliveryOrder
            const { number: invoiceNo, date:invoiceDate } = ocrInvoice
            this.$router.push({
              path: '/deliver-bill',
              query: {
                taskCode: 'DELIVERY_INVOICE',
                taskId,
                operation: 'edit',
                id,
                taskStatus,
                orderType,
                from: 'uploadInvoice',
                invoiceUrl: imgUrl,
                invoiceNo,
                invoiceDate: invoiceDate ? dayjs(invoiceDate.replace('年', '-').replace('月', '-').replace('日', '')).format('YYYY-MM-DD') : '',
                invoiceArriveDate: invoiceStoreTime ? dayjs(invoiceStoreTime).format('YYYY-MM-DD') : ''
              }
            })
          }
        }
      }).catch((err) => {
        loading.hideLoading()
        this.$toast(err)
      })
    },
    reShoot() {
      this.fileList = []
      this.ocrTxt = ''
    },
    goDetail() {
      if (!this.jumpTo) return
      const { id } = this.deliveryOrder
      this.$router.push({
        path: '/deliver-detail',
        query: {
          id,
          from: 'uploadInvoice',
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.invoiceModalBox{
  padding: 12px;
  /deep/ .van-dialog__header{
    padding-top: 0px;
  }
  .content{
    font-size: 13px;
    padding:0 0 10px 10px;
    color: #E4002C;
  }
}
.uploadVehicleInvoice{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /deep/ .van-uploader__upload{
    background: #fff;
    margin: 0 0 8px;
  }
}
</style>